




.header-proteccion{
    border-bottom: 0px!important;
    padding-right: 35px!important;   
    padding: 0px!important;
    padding-left: 20px!important;
    padding-right: 30px!important;
    padding-top: 5px!important;
}

.body-proteccion{
    padding: 0px!important;
}

.titulo-proteccion{
    color: #004990;
    padding-left: 20px;  
    padding-top: 60px; 
    font-family: Objectivity-Regular;
    font-weight: bolder;
     font-size: 40px;   
    font-weight: 700;
}





.politica-proteccion{
    color:#05294F;
    padding: 0px 20px; 
    
}

.politica-proteccion p{
    text-align: justify;
    font-family: Objectivity-Regular;

}

.politica-proteccion h5{
    font-family: Objectivity-Regular;
    color: #004990;
    font-weight: 600;
}

.barra-politica-proteccion{
    height: 6px;
    background: #99CA3E;
    opacity: 1;
    border-radius: 10px;
    margin-bottom: 20px;
}

.btn-close{
    padding-top: 0px!important;
}


