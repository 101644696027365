.bg-grandient-novedades{
    /* background-image: url('../../assets/img/back-degrad.png'); */
    background-image: url('../../assets/img/bg-section-grandient.png');
    background-size: cover;
    /* background-position: center; */
    background-position: right top;
    background-repeat: no-repeat;
    padding-bottom: 10px;
}

.bg-grandient-home{
    /* background-image: url('../../assets/img/back-degrad.png'); */
    background-image: url('../../assets/img/bg-grandient-section-2.png');
    background-size: cover;
    /* background-position: center; */
    background-position: right top;
    background-repeat: no-repeat;
    padding-bottom: 100px;
}

.bg-grandient-homee{
    background-image: url('../../assets/img/bg-grandient-home-test-2.jpg');
    background-size: cover;
    /* background-position: center; */
    background-position: right top;
    background-repeat: no-repeat;
    padding-bottom: 100px;
}

