#titulo-error{
  font-family: "Assistant-VariableFont_wght";
  font-size: 40px;
  color:#004990;
}

#subTitulo-error{
    font-family: "Assistant-VariableFont_wght";
    font-size: 30px;
    color:#004990;
}