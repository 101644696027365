.section-whatsapp{
    background-color: #f3f3f3;
    height: 85px;
    margin-top: -7px;
}

.section-whatsa__text{
    font-family: 'Assistant-VariableFont_wght';
    font-size: 18px;
    text-align: justify;
    color: #05294f;
    margin-left: 8px;
    margin-right: 8px;
}

.section-whatsa__icon{
    margin-left: 8px;
    margin-right: 8spx;
}