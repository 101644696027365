


/* MV */
@media (max-width: 991.98px){
    .section-footer__titulo{
       font-size: 1.1vw;   
       color: black;     
    }
    
    .section-footer__texto{
        font-size: 1.1vw;
        text-align: justify;       
        line-height: 1;
        color: black;
    }
    
}

/* PC*/
@media (min-width: 992px){
    .section-footer__titulo{
        font-size: 1.2vw;        
    }
    
    .section-footer__texto{
        font-size: 1.2vw;
        text-align: justify;        
        line-height: 1;
    }
    
}  

#footer{
    font-weight: 100!important;
}
.section-footer{
    background-color: #ededed;
    /* margin-top: 20px; */
    bottom: 0px;
    /* position: relative; */
    /* position: absolute; */
    width: 100%;    
}

.section-footer__privacidad{
    font-size: small;
    padding: 5px 0px 0px 10px;
    

}
.section-footer__texto{   

color: #909090;
font-size:smaller;
    
}

.section-footer__titulo{
    margin-left: 5px;
    margin-top: 4px;
    font-weight: bold;
    color: black;
    

}

.footer-icon{  
  height: 24.5px !important;
}