/* MV */

@media (max-width: 768px) {
  .card-body-principal{
    min-height: 200px!important;
    /* max-height: 200px;
    height: 200px; */
  }    
}

@media (max-width: 991.98px) {
  .indice-centro {
    margin-left: 2px;
    margin-right: 2px;    
  }

  .card-body-principal{
    min-height: 180px;
    /* max-height: 180px;
    height: 180px; */
  }

  .texto-card {
    color: #004990;
    font-size: 14pt !important;
    font-family: Assistant-VariableFont_wght;
    font-weight: 600;
    padding: 0px 10px 10px 10px;
  }

  .dropdown-toggle::after {
    border: 0px !important;
    content: '';
  }
}
.slick-slide {
  height: auto !important;
}
.slick-track {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important;
}

/* PC*/
@media (min-width: 992px) {
  .indice-centro {
    padding-left: 5px;
    padding-right: 5px;        
    
  }

  .texto-card {
    color: #004990;
    font-size: 12pt !important;
    font-family: Assistant-VariableFont_wght;
    font-weight: 600;
    padding: 0px 10px 10px 10px;
  }
}

.slick-next
{
  right: 0px !important;
}

.slick-prev{
  left: 0px !important;
  z-index: 2000;
}

.principal-card {
  border: 0px;
  box-shadow: 5px 2px 10px 0px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 5px 2px 10px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 5px 2px 10px 0px rgb(0 0 0 / 20%);
}

.hover-card {
  box-shadow: 5px 2px 10px 0px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 5px 2px 10px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 5px 2px 10px 0px rgb(0 0 0 / 20%);
}

.card-body-principal {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 160px;
  /* max-height: 160px;
  height: 160px; */
}


/* .principal-card:hover .card-img-principal{
    display: none
}

.principal-card:hover .card-body{
    display: none
}



.card.bg-transparent.principal-card:hover .card-body-principal{
    display: none
}

.card.bg-transparent.principal-card:hover .card-img-hover{
    display: block !important
} */


.container-cards:hover .principal-card {
  display: none;
}

.container-cards:hover .hover-card {
  display: block !important;
}


.slideCentrosMedicos .container-cards
{
  width: 320px !important;
  min-width: 320px !important;
  max-width: 320px !important;
}

.slideCentrosMedicos .indice-centro{
  display: flex;
  justify-content: center;
}

/* .slick-slide {
  height: 100%;
  width: 324px !important;
  min-width: 324px !important;
  max-width: 324px !important;
  display: flex;
  align-items: center;
  justify-content: center;
} */


.slideCentrosMedicos .slick-prev,
.slick-next {
  height: 40px !important;
}

.card-body-principal {
  min-height: 105px;
  padding-bottom: 5px !important;
}

.hover-card {
  min-height: 105px;
}

.principal-card img.card-img-top.card-img-principal {
  height: 200px;
  object-fit: cover;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.hover-card .card-img-principal {

  display: flex;
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.hover-card .card-img-principal div {
  width: 100%;
  height: 100%;
}

.hover-card .card-img-principal div iframe {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.custom-select-wrapper {
  position: relative;
  user-select: none;
}

.custom-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  /* border-width: 0 2px 0 2px;
    border-style: solid;
    border-color: #394a6d;
    border-radius: 12px; */
  /* margin: 10px auto; */
}

.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
  font-weight: 300;
  color: white;
  height: 60px;
  line-height: 60px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 30px;
  background-color: #004990;
  font-size: 16pt;
  font-family: Assistant-VariableFont_wght;
  box-shadow: 10px 8px 26px -6px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 10px 8px 26px -6px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 10px 8px 26px -6px rgba(0, 0, 0, 0.35);
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border-top: 0;
  background-color: #004990;
  color: #fff;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  font-size: 16pt;
  font-family: Assistant-VariableFont_wght;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  flex-direction: column;
}

.custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.custom-option {
  position: relative;
  display: block;
  padding: 0 22px 0 22px;
  font-size: 22px;
  font-weight: 300;
  color: #fff;
  line-height: 60px;
  cursor: pointer;
  transition: all 0.5s;
}

.custom-option:hover {
  cursor: pointer;
  background-color: #5b79ab;
}

.custom-option.selected {
  color: #ffffff;
  background-color: rgba(34, 74, 141, 0.55);
}

.arrow {
  position: relative;
  height: 15px;
  width: 15px;
}

.arrow::before,
.arrow::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0.15rem;
  height: 100%;
  transition: all 0.5s;
}

.arrow::before {
  left: -5px;
  transform: rotate(-45deg);
  background-color: white;
}

.arrow::after {
  left: 5px;
  transform: rotate(45deg);
  background-color: white;
}

.open .arrow::before {
  left: -5px;
  transform: rotate(45deg);
}

.open .arrow::after {
  left: 5px;
  transform: rotate(-45deg);
}

.lastDropDown {
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
}

.redondear_bordes {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.quitar_bordes {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

a {
  text-decoration: none !important
}

.slideCentrosMedicos .slick-prev,
.slick-next {
  height: 30px !important;
  width: 15px !important;
}

.rootSelect{  
  margin: 0 auto;
}