.bg-internacion {
  background-image: url("../../../assets/img/bg-internacion.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-internacion-gray {
  background-color: #dedbd9;
}

.internacion__titulo {
  color: #05294f;
  font-size: 50px;
  padding-top: 18px;
}

.internacion__titulo2 {
  color: #05294f;
  font-size: 40px !important;
  font-weight: 700;
  padding-top: 16px;
}

.grandiend-btn {
  background: rgb(33, 76, 143) !important;
  background: linear-gradient(
    180deg,
    rgba(33, 76, 143, 1) 44%,
    rgba(42, 106, 159, 1) 60%
  ) !important;
  color: white !important;
  border-radius: 20px !important;
}
