.bg-internacion-gray{
    background-color: #dedbd9;    
    width: calc(100vw);
}

.internacion__titulo{
    color: #05294F;
    font-size: 40px !important;
    font-weight: 700;
}

/* .internacion__titulo_comunicacion{
    color: #05294F;
    font-size: 40px;
    font-weight: 700;
} */

.grandiend-btn{
    background: rgb(33,76,143) !important;
    background: linear-gradient(180deg, rgba(33,76,143,1) 44%, rgba(42,106,159,1) 60%) !important; 
    color: white !important;
    border-radius: 20px !important;
}

/* @media only screen and (max-width: 767px) {
    iframe{
        width: 100% !important;      
    }
} */

iframe{
    width: 100% !important;    
    max-width: 900px;    
}

.imagen-template1
{
    /* width: 100vw; */
}