/* MV */
@media (max-width: 991.98px) {
  .botones-grupo-video {
    display: flex;
    flex-direction: column;
  }
  .botones-grupo-video button {
    margin-bottom: 10px;
  }
  .home-video-captions {
    color: #f1f1f1;
    position: absolute;
    bottom: 15%;
    left: 5%;
  }
  .home-video-captions h1 {
    text-align: center;
  }
  .home-video-captions h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  .slick-dots {
    bottom: 20px !important;
    left: 50% !important;
    transform: translateX(-50%) !important; /* Centro horizontal */
    display: flex !important;
    justify-content: center;
  }
}

/* PC*/
@media (min-width: 992px) {
  .botones-grupo-video {
    display: flex;
    flex-direction: row;
  }
  .home-video-captions {
    color: #f1f1f1;
    position: absolute;
    bottom: 30%;
    left: 5%;
  }

  .slick-dots {
    bottom: 20px !important; /* Ajusta según la altura que desees */
    left: 50% !important; /* Centrado horizontal */
    transform: translateX(-50%) !important; /* Para centrar en el eje X */
    display: flex !important; /* Asegura que los dots estén alineados en fila */
    justify-content: center;
  }
}

@media (max-width: 1600px) {
  .home-video {
    height: 400px !important;
  }
}

@media (max-width: 1360px) {
  .home-video {
    height: 320px !important;
  }
}

@media (max-width: 600px) {
  .home-video {
    /* position: relative; */
    height: 440px !important;
  }
}

/* .btnSlide {
  color: #05294f !important;
  background: #fff !important;
  border-radius: 20px !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5); /* Definir la sombra con color gris 
} */

.fondo-difuminado {
  text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff,
    0 0 10px #fff;
  color: #05294f !important;
}

.home-video {
  position: relative;
  height: 440px;
}

.home-video-slide {
  width: 100%;
  z-index: -1;
}

.home-video-captions h1 {
  font-size: 55px;
  font-family: "Assistant-VariableFont_wght";
  color: #004990;
}

.home-video-captions h3 {
  font-family: "Assistant-VariableFont_wght";
  color: #004990;
}

.home-video-captions__btnestilo1 {
  background: #004990 !important;
  color: white !important;
  border-radius: 30px !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
}

.home-video-captions__btnestilo2 {
  background: #004990 !important;
  color: white !important;
  border-radius: 20px !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
}

.home-video-captions__btnestilo3 {
  background: #129bc6 !important;
  color: white !important;
  border-radius: 20px !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
}

.home-video-captions__btnestilo4 {
  border-radius: 20px !important;
  color: #05294f !important;
  background: #fff !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  font-weight: 500 !important;
}

.arrowDownButton {
  position: absolute;
  bottom: 30px;
  left: 50%;
}

.slick-dots li {
  margin-right: 10px !important; /* Espacio entre los dots */
}

.slick-dots li button {
  background-color: #8a8a8a !important;
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important; /* Dots redondos */
  padding: 0 !important; /* Elimina el padding extra */
}

.slick-dots li.slick-active button {
  background-color: #fff !important; /* Color de dot activo */
}

.slick-dots li button:before {
  content: "" !important;
}

.home-video-imagen {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* slide.css */
.arrow-hover {
  transition: all 0.3s ease-in-out;
  display: inline-block;
  padding: 10px;
  border-radius: 50%;
}

.arrow-hover:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.4); /* Agrega un fondo sutil si lo deseas */
  transform: scale(1.1);
}


