
.social-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.instagram-media {
  max-height: 800px;
}

.social-container-small {
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: white;
  padding: 10px;
  width: 100%;
}

.social-container-small-ig {
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: white;
  padding: 10px;
  width: 100%;
  align-items: end;
}

.instagram-container div iframe{
  min-width: 0px !important;
}

.col-instagram{
  align-items: end;
}

.section-social-movil_titulo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Assistant-VariableFont_wght";
  padding-top: 20px;
  padding-bottom: 20px;
}
.section-social-movil_titulo h2 {
  font-size: 30pt;
}

#items-social-container img,
#items-social-container iframe {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.social-container-small iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  border: 0;
}

.social-container-small blockquote {
  max-width: 100% !important;
}

.instagram-container {
  max-width: 100%;
}

.col-lg-6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}


@media (min-width: 769px) {
  .container-social-network-home {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .contenedor-youtube {
    position: relative;
    width: 100%;
  }
  
  
}

@media (max-width: 768px) {
  .container-social-network-home {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .contenedor-youtube {
    position: relative;
    width: 100%;
  }
  
  

  .social-container-small {
    margin-bottom: 20px;
  }

  .instagram-container {
    max-width: 100%;
  }

  .col-instagram{
    align-items: center;
  }
  
}
