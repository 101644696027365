@media (max-width: 765px) {
  .center-title {
    padding-top: 15px;
  }
}

h2.header-accordion.accordion-header button {
  background: transparent;
  color: #05294f;
  font-weight: 700;
  font-family: "Assistant-VariableFont_wght";
  font-size: 23px;
}

/* .header-accordion.accordion-header{
    width: 97%;
    margin: 10px;
} */

.accordion-item {
  border-radius: 15px !important;
  border: 0;
  -webkit-box-shadow: 1px 2px 12px 0px rgba(3, 102, 195, 0.2);
  -moz-box-shadow: 1px 2px 12px 0px rgba(3, 102, 195, 0.2);
  box-shadow: 1px 2px 12px 0px rgba(3, 102, 195, 0.2);
  margin-top: 15px;
  padding: 5px 0px;
}

.accordion-button:not(.collapsed) {
  border-bottom: 2px solid #c7e2fc;
}

.accordion-button {
  width: 97%;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC);
  transform: rotate(-180deg);
}

.accordion-button::after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC) !important;
  background-position: calc(100% - 0px) 5px, calc(100% - 20px) 15px, 100% 0 !important;
}

.accordion-button::after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC) !important;
  background-position: calc(100% - 0px) 5px, calc(100% - 20px) 15px, 100% 0;
}

/* MV */
@media (max-width: 991.98px) {
  .detalle-centro-medico {
    /* margin-top: 20px!important; */
  }

  .accordion-item {
    margin-bottom: 10px;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .topInfo {
    margin: 0 5px 0 5px !important;
  }
}

/* PC*/
@media (min-width: 992px) {
}

.detalle-centro-medico {
  color: #05294f;
  /* margin-top: 30px !important; */
}

.hb-online {
  color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px !important;
  -webkit-box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.13);
  box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.13);
}

.topInfo {
  margin: 0 2rem 0 2rem;
}

.btnSeleccionado {
  color: white !important;
  background-color: #004990 !important;
}
