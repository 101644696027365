/* MV */
@media (max-width: 991.98px) {
    .contenido-centro-medico {
        margin-top: 0px !important;
    }
    .container-centros
    {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

/* PC*/
@media (min-width: 992px) {
    .contenido-centro-medico {
        margin-top: 45px !important;
    }
    .container-centros
    {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}


.section-centros_medicos{
    width: calc(100vw - 17px);
}

.section-centros_medicos_titulo {
    font-size: 42pt;
    font-family: 'Assistant-VariableFont_wght';
    color: #004990;
}

.section-centros_medicos_titulo_movil {
    font-size: 30pt;
    text-align: center;
    font-family: 'Assistant-VariableFont_wght';
    color: #004990;
}