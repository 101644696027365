.logosFooter {
  height: 80px;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  #itaes {
    height: 55px;
  }

  #safe-travel {
    height: 70px;
  }
}
