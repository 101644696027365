/* MV */
/* @media (max-width: 991.98px){ */

@media (min-width: 1px) and (max-width: 600px) {
  .card-body2 {
    height: 700px !important;
  }
}

/* PC*/
@media (min-width: 992px) {
}

.card-centros-medicos .card-title {
  color: #05294f;
  font-size: 30px;
  font-weight: 700;
}

.card-body2 {
  height: 650px;
  overflow: hidden;
}

.card-centros-medicos {
  -webkit-box-shadow: 4px 7px 10px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 4px 7px 10px 0px rgba(0, 0, 0, 0.14);
  min-height: 650px;
  /* height: 750px; */
  color: #05294f;
  height: 100%;
}

.container-centros .col-md-6 {
  margin-bottom: 15px;
}

.card-centros-medicos .card-text {
  font-size: 18px;
}

.card-centros-medicos .card-foto img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 240px;
}

.foto-centro {
  border-radius: 10px;
}

.btnCentroHB {
  background: #fff !important;
}

.btnCentroHB:hover {
  color: white !important;
  background-color: #004990 !important;
}
