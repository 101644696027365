.section-bajarapp{
    background-color:#004990;
    color: white;
}

.section-bajarapp__imagen{
    margin-top: -70px;
}

.section-bajarapp__texto{
    margin-top: 40px;
    font-family: 'Assistant-VariableFont_wght';
    flex-direction: column;
    margin-left: 40px;
    padding-top: 70px;
}

.section-bajarapp__texto h3{
    font-size: 30px;
    font-family: 'Assistant-VariableFont_wght';
}