
.custom__menu__container{
    display: block;
    top: 94px;
    width: 100%;
    opacity: 1;
    z-index: 5;
    overflow: hidden;
    position: absolute;
}

.custom__menu__item{
    background-image: url('../../../assets/img/bg-curvita.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    min-height: 350px;
    margin-top: 15px;
}

.custom__menu___list_item ul{
list-style-type: none;
}

.custom__menu___list_item ul li a{
    text-decoration: none;
    font-family: 'Assistant-VariableFont_wght';
    color: white;
    font-size: 20px;
}

.custom__menu___list_item ul li a:hover{
    color: #05294F;
    transform: scale(1.2);
    transition: all 0.4s ease;
}

.custom__menu___list_item ul li{
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
    padding: 5px;
    border-top: 2px solid #8f9191;
}

.custom__menu___list_item ul li:last-child{
    border-bottom: 2px solid #8f9191;
}