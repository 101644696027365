/* MV */
@media (max-width: 991.98px) {
    .container-como-llegar {
        margin-top: 20px !important;        
    }

   

}

/* PC*/
@media (min-width: 992px) {
  
}

.btn-como-llegar-ver-mas {
    padding: 5px !important;
    color: #fff !important;
    border-radius: 20px !important;
    border: 1px solid #05294F !important;
    background-color: #05294f!important;
    margin-left: 10px;
    margin-top: 20px!important;
}

.bg-gray {
    background-color: #DBDAD7;
}

.como_llegar__titulo {
    color: #05294F;
    font-size: 38px;
    font-weight: 700
}

.grandiend-btn-como-llegar {
    background: rgb(0, 73, 144);
    /* background: linear-gradient(180deg, rgba(0, 73, 144, 1) 0%, rgba(1, 8, 16, 1) 100%); */
    background: linear-gradient(180deg, #014289, #014289);
    color: white !important;
    text-align: left !important;
    -webkit-box-shadow: 0px 8px 16px 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 16px 3px rgba(0, 0, 0, 0.15);
}

.map-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.map-container div {
    flex-grow: 1;
}

.map-container div iframe {
    flex-grow: 1;
    border-radius: 20px;
    -webkit-box-shadow: 5px 6px 8px -2px rgba(0, 0, 0, 0.13);
    box-shadow: 5px 6px 8px -2px rgba(0, 0, 0, 0.13);

    height: 100%;
    width: 100%;

}

.card-como-llegar {
    -webkit-box-shadow: 4px 7px 10px 0px rgba(0, 0, 0, 0.14);
    box-shadow: 4px 7px 10px 0px rgba(0, 0, 0, 0.14);
}