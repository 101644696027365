/* MV */
/* @media (max-width: 991.98px){ */

@media (min-width: 1px) and (max-width: 991.98px) {
  .customNavbar {
    padding-left: 5px !important;
    padding-right: 5px !important;
    -webkit-box-shadow: 0px 5px 6px -2px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 5px 6px -2px rgba(0, 0, 0, 0.16);
    z-index: 1;
  }

  .grandientNavbar_element1 {
    margin: auto !important;
  }

  .socialMediaMobile {
    height: 20px !important;
  }
}

/* PC*/
@media (min-width: 992px) {
  .customNavbar {
    padding-left: 24px !important;
    padding-right: 24px !important;
    -webkit-box-shadow: 0px 5px 6px -2px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 5px 6px -2px rgba(0, 0, 0, 0.16);
    z-index: 1;
  }
}

.grandientNavbar_elements {
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grandientNavbar_element1 {
  margin-left: 20px;
}

.grandientNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 33px;
  background: transparent
    linear-gradient(180deg, #05294f 0%, #004990 11%, #3fb2c9 100%, #6cb2f1 100%)
    0% 0% no-repeat padding-box;
}

.footer-icon {
  height: 24.5px !important;
}

.phoneText {
  font-size: 16px;
  color: white;
  /* margin-right: auto; */
  /* margin-left: 5px; */
  /* font-family: Objectivity-Regular; */
}

/* .grandientNavbar_icon {} */

.grandientNavbar_searchInput {
  height: 28px;
  margin-right: 5px;
  margin-left: 5px;
}

.grandientNavbar_separator {
  border-right: 1px solid white;
  height: 28px;
  margin-left: 5px;
  margin-right: 5px;
}

.grandientNavbar_idioma {
  color: #ffffff;
  font-size: 12px;
  line-height: 2.3;
  align-items: center;
  display: flex;
}

.grandientNavbar_idioma_icon {
  height: 20px;
  margin-left: 5px;
}

.customNavbar_brand {
  display: flex;
}

.customNavbar_brand_logo {
  /* width: 7em; */
  width: 150px;
  height: auto;
}

.customNavbar_brand_logo_separator {
  border-right: 2px solid #b6b8bb;
  margin-left: 10px;
  margin-right: 10px;
  height: 52px;
}

.customNavbar_brand_button {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.customNavbar_brand_button_home {
  width: 24px;
}

.nav-mega {
  width: 100%;
}

a#basic-nav-dropdown {
  text-align: center;
}

/* .nav-mega .dropdown { 
    position: static; 
} */

/* .nav-mega .dropdown .dropdown-menu.show {
    max-width: 1140px;
    min-width: auto;
    width: 100%;
    margin: auto;
    right: 0;
    padding: 0;
    border-color: #ccc;
    height: 361px;
} */

/* .nav-mega .dropdown .dropdown-menu.show li{
    padding: 20px;
}

.nav-mega .dropdown .media-list {
    padding: 10px; font-size: 13px;
    
} */

/* .nav-mega .dropdown-toggle:after{
    display:none
} */

/* .dropdown-menu.show:before {
    content: "";
    background: url('../../assets/img/onda-pacientes.png');
    background-repeat: no;
    width: 100%;
    height: 120px;
    position: absolute;
    top: 100%;
    
} */

/* .nav-mega .dropdown .dropdown-menu.show::before {
    border-radius: 100%;
    position: absolute;
    background: black;
    right: -200px;
    left: -200px;
    top: -200px;
    content: '';
    bottom: 0;
  } */

/* Normal Button */

.navItem__button {
  color: #05294f !important;
  font-family: "Assistant-VariableFont_wght";
  font-size: 16pt !important;
  margin: auto 0.8em auto;
}

.navItem__button:hover {
  transform: scale(1.2);
  transition: all 0.4s ease;
}

.btn.navItem__button--quienessomos {
  width: auto;
  white-space: nowrap;
  color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 10px;
  -webkit-box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.13);
  box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.13);
}

.botonSub {
  margin: 0;
  /* padding-right: 5px; */
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 15px !important;
  display: inline-block;
}

/* Button Plan de salud */

.btn.navItem__button--plandesalud {
  width: 130px;
  color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
  -webkit-box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.13);
  box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.13);
}

.btn.navItem__button--plandesalud:hover {
  color: white;
  font-weight: 800;
  background-color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
}

/* Button HB Online */

.btn.navItem__button--hb {
  color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
  -webkit-box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.13);
  box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.13);
}

.btn.navItem__button--hb:hover {
  color: white;
  background-color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
}

.btn.navItem__button--hb:hover .color {
  display: none;
}

.btn.navItem__button--hb .transparent {
  display: none;
}

.btn.navItem__button--hb:hover .transparent {
  display: initial;
}

a#basic-nav-dropdown.dropdown-toggle.nav-link {
  padding: 0px !important;
}

.dropdown-toggle::after {
  display: none !important;
  margin-left: 0 !important;
  vertical-align: 0 !important;
}

.dropdown-menu {
  border: 0 !important;
  padding: 0 !important;
  padding-right: 10px !important;
}

.dropdown-item {
  padding: 0.25rem 0 !important;
  color: #004990 !important;
  border-bottom: 1px solid #abc3da !important;
  width: 90% !important;
}

.dropdown-menu-mv {
  color: #05294f;
  /* #004990; */
  border-bottom: 1px solid #abc3da;
  font-size: 1.4rem;
  font-weight: 400;
}

.convenios {
  color: #004990;
}

.item-menu-mv {
  font-weight: 600;
}

.subitem-menu-mv {
  font-size: 20px;
  padding-left: 5px;
  color: #004990 !important;
}

.navbar-collapse-mv {
  border-top: 1px solid #b6b8bb;
  padding-top: 10px;
}
