/* MV */
@media (max-width: 991.98px) {
  .container-dejanos-consulta {
    padding: 20px !important;
  }

  .img-card {
    display: none;
  }

  .section_contacto_titulo {
    padding-bottom: 10px;
  }

  .text-danger-custom {
    margin-left: 0 !important;
  }
}

/* PC*/
@media (min-width: 992px) {
  .container-dejanos-consulta {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img-card-mobile {
    display: none;
  }

  .text-danger-custom {
    margin-left: 22% !important;
  }
}

.section_contacto .text-danger {
  font-size: 14px;
  height: 21px !important;
}

.container-dejanos-consulta {
  padding: 30px !important;
}

.section_contacto_titulo {
  color: #05294f;
  font-family: "Assistant-VariableFont_wght";
  font-size: 30px;
}

.section_contacto p {
  color: #05294f;
  font-family: "Assistant-VariableFont_wght";
  font-size: 25px;
  line-height: 25px;
}

.section_contacto h4 {
  color: #05294f;
  font-family: "Assistant-VariableFont_wght";
  font-weight: 600;
}

.section_contacto label {
  color: #05294f;
  font-family: "Assistant-VariableFont_wght";
  font-size: 19px;
}

select {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC);
  background-position: calc(100% - 20px) 12px, calc(100% - 20px) 14px, 100% 0;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.section_contacto_form {
  width: 100% !important;
}

.section_contacto_form_titulo {
  color: #05294f;
  font-family: "Assistant-VariableFont_wght";
  font-size: 30px;
}

.btn_section_contacto {
  color: white;
  background: #05294f;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #05294f;
}

.section_contacto_form label {
  font-size: 16px;
}

.section_contacto_form input {
  border: 1px solid #05294f;
}

.section_contacto_form select {
  border: 1px solid #05294f;
}

.section_contacto_form textarea {
  border: 1px solid #05294f;
}

.shadow-blue {
  -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
}

.shadow-external {
  -webkit-box-shadow: 0px 2px 15px -2px rgba(0, 0, 0, 0.28) !important;
  box-shadow: 0px 2px 15px -2px rgba(0, 0, 0, 0.28) !important;
}

.subtitulo {
  font-size: 16px !important;
}

.info-contacto {
  color: #0d6efd;
}

#botCaptcha {
  transform: scale(0.71);
  transform-origin: 0 0;
}
