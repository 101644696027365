/* Padding utils */

.px-4-custom {
    padding-right: 2.1rem!important;
    padding-left: 2.1rem!important;
}

/* Width utils */

.w-90{
    width: 90% !important
}

/* Border Radius Utils */

.b-radius-0{
    border-radius: 0px;
}

.b-radius-10{
    border-radius: 10px;
}

.b-radius-20{
    border-radius: 20px;
}

.b-radius-r-20{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.b-radius-l-20{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.b-radius-30{
    border-radius: 30px;
}

.b-radius-40{
    border-radius: 40px;
}

.b-radius-50{
    border-radius: 50px;
}
/* Fonts Utils */

.ff-Assistant-VariableFont_wght{
    font-family: 'Assistant-VariableFont_wght';
}

/* Font Size Utils */

.f-size-10{
font-size: 10px;
}

.f-size-11{
    font-size: 11px;
}

.f-size-12{
    font-size: 12px;
}

.f-size-13{
    font-size: 13px;
}

.f-size-14{
    font-size: 14px;
}

.f-size-15{
    font-size: 15px;
}

.f-size-16{
    font-size: 16px;
}