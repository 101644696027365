.internacion__titulo{
    color: #05294F;
    font-size: 50px
}

.grandiend-btn{
    background: rgb(33,76,143) !important;
    background: linear-gradient(180deg, rgba(33,76,143,1) 44%, rgba(42,106,159,1) 60%) !important; 
    color: white !important;
    border-radius: 20px !important;
}
