.bg-novedades{
    background-image: url('../../assets/img/bg-novedades.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.card-novedades{
    min-height: 375px;
    height: 100% !important;
}

.card-novedades.bg-azul{
    background: #05294F;
    color: white
}

.card-novedades .card-title {
    margin-top: 10px;
    font-size: 28px;
    font-family: Assistant-VariableFont_wght;
    line-height: 34px;
    font-weight: 700;    
}

@media (max-width: 1400px) {
    .novelty-main-title  {
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;      
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

.card-novedades .card-title.opc2 {
    font-size: 23px;
    line-height: 30px;
    font-family: Assistant-VariableFont_wght;
    color: #05294f;
    font-weight: 600;
}

.card-novedades .card-title.opc3 {
    color: #05294f
}

.card-foto
{
    padding-top:0px;
}

.card-novedades .card-foto img {
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    height: 170px;
}


.card-descripcion-corta.opc1{
    font-size: 22px;
    display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-descripcion-corta.opc3 {
    font-size: 22px;
    color: #05294f;
}

.card-novedades hr.separador.opc1 {
    opacity: 0.6 !important;
    height: 3px !important;
    color: white
}
.card-novedades hr.separador.opc3 {
    opacity: 0.6 !important;
    height: 3px !important;
    color: #05294f
}

.card-novedades{
    -webkit-box-shadow: 6px 11px 24px 3px rgba(0,73,144,0.18);
box-shadow: 6px 11px 24px 3px rgba(0,73,144,0.18);
}

.title_novedades_h2{
    font-size: 65px;
    color: #05294f;
    font-family: Assistant-VariableFont_wght;
    font-weight: 700;
}

.titulo-novedades
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.no-border
{
    border:none !important;
}

.boton-paginacion:disabled
{
    opacity: 0.2 !important;
}

