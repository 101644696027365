/* MV */
@media (max-width: 991.98px){
    .section-nuestros-accesos {
        background-color: rgba(0, 73, 144, 0.1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px -4px 8px rgba(0, 0, 0, 0.1);
    }
    
}

/* PC*/
@media (min-width: 992px){
    .section-nuestros-accesos {
        background-color: rgba(0, 73, 144, 0.1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px -4px 8px rgba(0, 0, 0, 0.1);
    }
    
}

.content {
    font-family: "Assistant-VariableFont_wght";
    font-size: "22pt";
    overflow: "hidden";
}

.title {
    text-align: "justify";
    font-family: "Assistant-VariableFont_wght";
    color: "#ffffff";
    /* //font-size: '5.7vw'; */
    font-size: "4.08vw";
    /* //margin-bottom: '50px'; */
    margin-bottom: "4px";
    font-weight: "normal";
}


@media (max-width: 768px) {
    .container-nuestros-accesos-home {
        padding: 80px;
        padding-bottom: 80px;
    }
}
  
@media (min-width: 769px) {
    .container-nuestros-accesos-home {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.acceso {
    color: #ffffff;
    float: left;
    width: 33.33%;
    /* border: 5px solid #ffffff; */
    height: 350px;
    font-size: 0;
    text-align: center;
    padding-top: 75px;
    /* border-radius: 10px; */
}

/* .makeStyles-acceso-84 {
    color: #ffffff;
    float: left;
    width: 33.33%;
    border: 5px solid #ffffff;
    height: 18.9vw;
    font-size: 0;
    text-align: center;
    padding-top: 75px;
} */

.acceso1 {
    /* background-image: url('../../../assets/img/acceso-1.png'); */
    background-size: "100% 100%";
    border-right: 15px solid white;
    border-left: 15px solid white;
    border-top: 15px solid white;
    border-bottom: 15px solid white;
}

.acceso2 {
    /* background-image: url('../../../assets/img/acceso-2.png'); */
    background-size: "100% 100%";
    border-right: 15px solid white;
    border-top: 15px solid white;
    border-bottom: 15px solid white;
}

.acceso3 {
    /* background-image: url('../../../assets/img/acceso-3.png'); */
    background-size: "100% 100%";
    border-right: 15px solid white;
    border-top: 15px solid white;
    border-bottom: 15px solid white;
}

.acceso4 {
    /* background-image: url('../../../assets/img/acceso-4.png'); */
    background-size: "100% 100%";
    border-right: 15px solid white;
    border-left: 15px solid white;
    border-bottom: 15px solid white;
}

.acceso5 {
    /* background-image: url('../../../assets/img/acceso-5.png'); */
    background-size: "100% 100%";
    border-right: 15px solid white;
    border-bottom: 15px solid white;
}

.acceso6 {
    /* background-image: url('../../../assets/img/acceso-6.png'); */
    background-size: "100% 100%";
    border-right: 15px solid white;
    border-bottom: 15px solid white;
}

.panelaccesos {
    overflow: hidden;
    box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 32%) 0px 2px 16px 0px;
    margin-left: auto;
    margin-right: auto;
    /* border-left: 6px solid white;
    border-right: 6px solid white; */
    background-size: 100% 100%;

}

.panelaccesos_probando {
    /* border: 9px solid white; */
    border-radius: 10px;
}

.contMD {
    display: "block";
    /* padding-left: '8%';
    padding-right: '8%';
    padding-left: "13%";
    padding-right: "13%"; */
}

;

/* .contXS {
    display: "none";
    padding-left: "6%";
    padding-right: "6%";
} */

.titleXS {
    text-align: "center";
    font-family: "Assistant-VariableFont_wght";
    color: "#ffffff";
    font-size: "6vw";
    margin-bottom: "25px";
    font-weight: "normal";
}

.accesoXS {
    float: "left";
    width: "50%";
    border: "10px solid #ffffff";
    height: "206px";
    padding-top: "75px";
    color: "#ffffff";
    text-align: "center";
    font-size: "0";
}

.panelaccesosXS {
    overflow: "hidden";
    margin-left: "auto";
    margin-right: "auto";
    width: "100%";
    background-size: "100%";
    border: "4px solid #ffffff";
    border-radius: "10px";
    background-position: "-1px -1px";
}

.title_grid {
    transition: all 0.0s ease-in !important;
}

.caja-acceso {
    border: 10px solid #fff;    
    padding-top:13px!important;
    padding-bottom:13px!important;
    padding-left:13px!important;
    padding-right:13px!important;
    margin-bottom: 10px;    
}