@font-face {
  font-family: "Assistant-VariableFont_wght";
  src: url("./assets/fonts/Assistant-VariableFont_wght.ttf")
}


* {
  box-sizing: border-box;  
}

body {
  margin: 0;
  background-color: white !important;
  font-family: "Assistant-VariableFont_wght";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  height: 100vh;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
}

#root{
  height: 100vh;  
}



.title_reusable_components{
  color: #05294f;
  font-size:30px;
  font-weight: 700;
}

.separador_reusable_components {
  background: #1c5d9c;
  height: 3px !important;
  opacity: unset !important;
  -webkit-box-shadow: 10px 10px 9px -9px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 9px -9px rgba(0,0,0,0.75);
box-shadow: 10px 10px 9px -9px rgba(0,0,0,0.75);
}
.contenido{
  color: #05294f;
  
}

.btn-azul{
  background-color: #05294f !important;
  color: white !important;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 5px 7px 14px -4px rgba(0,0,0,0.19);
-webkit-box-shadow: 5px 7px 14px -4px rgba(0,0,0,0.19);
-moz-box-shadow: 5px 7px 14px -4px rgba(0,0,0,0.19);
}

.card-contenido-reusable{
  -webkit-box-shadow: 7px 7px 9px 0px rgba(0,0,0,0.16); 
  box-shadow: 7px 7px 9px 0px rgba(0,0,0,0.16);
}


.bg-gray{
  background-color: #dedbd9;
}

#plantilla-principal {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  /* grid-template-areas:
    "header"
    "main"
    "footer"; */
}

.img-fluid
{
  height: auto !important;
}

/* #header-principal {
  grid-area: header;
}

#main-principal {
  grid-area: main;
}

#footer-principal {
  grid-area: footer;
} */