/* MV */

/* @media (min-width: 1px) and (max-width: 991.98px) {
  .div-search {
  }
} */

.img-cruz {
  height: 15px;
  padding-bottom: 2px;
}

.div-search {
  border-radius: 30px;
  width: 100%;
  border: 2px solid #004990;
  min-height: 45px;
  color: #7a7a7a;
  padding-left: 10px;
  padding-right: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  font-family: "Assistant", sans-serif;
  border-color: #004990;
}

.div-search:focus {
  outline: none;
}

.opcion-buscar {
  position: absolute;
  right: 0px;
  min-height: 43px;
  top: 1px;
  /* width: 10%; */
  width: 50px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #004990;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-vertodas-home {
  position: relative;
  width: 110px;
  height: 45px;
  margin-left: 10px;
}

.btn-vertodas-home:hover {
  background-color: white !important;
  color: #004990 !important;
  border: 1px solid #004990 !important;
}

.contenedor-buscador-personalizado {
  position: relative;
  width: 80%;
  /* min-width: 400px; */
  min-height: 45px;
  background: white;
  border-radius: 30px;
}

.contenedor-listado {
  border-radius: 20px;
  border: 2px solid #004990;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 1) !important;
}

.opcion-cancelar {
  position: absolute;
  right: 0px;
  height: 45px;
  /* width: 10%; */
  width: 50px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.input-search {
  border: 0;
  width: 80%;
  height: 41px;
  color: #004990;
  position: absolute;
  outline: none;
  padding-left: 0px;
  margin-left: 15px;
  margin-right: 130px;
}

.linea-divisora {
  border-bottom: 2px solid #004990;
  margin-top: 45px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 5px;
}

.lista-opciones {
  cursor: pointer;
}

.lista-opciones div span {
  padding: 5px 5px 5px 15px;
}

.lista-opciones div {
  padding: 5px 5px 5px 0px;
}

.lista-opciones div:last-child {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

/* .lista-opciones div:hover {
  background-color: #05294f;
  color: white;
} */

.item-seleccionado{
  background-color: #004990;
  color: white;
}