/* MV */
@media (max-width: 991.98px) {

    .section-estudia_titulo {
        font-size: 30pt;
        font-family: 'Assistant-VariableFont_wght';
        color: #004990;
        text-align: center;
    }

    .section-estudia_texto {
        font-size: 26px;
        font-family: 'Assistant-VariableFont_wght';
        color: #004990;
        text-align: center;
    }

    .section-estudia_boton_info {
        
        border-radius: 30px;
        border: 1px solid #004990;
        color: #fff;
        background-color: #004990;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 20px;
    }
    .container-estudia
    {
        padding-top: 80px;
        padding-bottom: 80px;
    }


}

/* PC */
@media (min-width: 992px) {
    .section-estudia_titulo {
        font-size: 35px;
        font-family: 'Assistant-VariableFont_wght';
        color: #004990;
        text-align: start;
    }

    .section-estudia_texto {
        font-size: 26px;
        font-family: 'Assistant-VariableFont_wght';
        color: #004990;
    }

    .section-estudia_boton_info {
        border-radius: 30px;
        border: 1px solid #004990;
        color: #fff;
        background-color: #004990;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .container-estudia
    {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}


.section-estudia {
    padding-left: 4px;
    padding-right: 4px;
    background-color: rgba(0, 73, 144, 0.1); 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px -4px 8px rgba(0, 0, 0, 0.1);
}


.section-estudia_boton_info:hover {
    color: #fff;
    background-color: #004990;
}

.imagen-estudia {
    box-shadow: 7px 10px 26px -6px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 7px 10px 26px -6px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 7px 10px 26px -6px rgba(0, 0, 0, 0.17);
}
@media (max-width: 768px) {
    .descripcion-texto {
      font-size: 20px; /* Ajuste del tamaño de fuente para móviles */
    }
  }
  
  /* PC */
  @media (min-width: 769px) {
    .descripcion-texto {
      font-size: 20px; /* Tamaño de fuente original para pantallas más grandes */
    }
  }