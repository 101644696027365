.section-novedades_titulo {
  text-align: right;
  position: relative;
  /* padding-bottom: 20px; */
  /* top: 50px; */
  margin-bottom: -35px;
}

.section-novedades_titulo h2 {
  font-size: 42pt;
  font-family: "Assistant-VariableFont_wght";
}

.section-novedades-movil_titulo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Assistant-VariableFont_wght";
  padding-top: 20px;
  padding-bottom: 20px;
}

.section-novedades-movil_titulo h2 {
  font-size: 30pt;
}

/* .section-novedades-movil_titulo a{
    text-align: center;      
} */
/* Media queries */

@media (min-width: 768px) and (max-width: 991.98px) {
  .section-novedades_titulo {
    text-align: center !important;
    position: unset !important;
    top: initial !important;
    padding: 25px;
  }
  .tabletScreen {
    height: 430px !important;
  }
}

.section-novedades_button_rounded {
  padding: 8px;
  border-radius: 30px;
  border: 1px solid #004990;
  color: #004990;
  background-color: white;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
}

.section-novedades_button_rounded:hover {
  padding: 8px;
  border-radius: 30px;
  border: 1px solid #004990;
  color: white !important;
  background-color: #004990;
  padding-left: 20px;
  padding-right: 20px;
}

.section-novedades-movil_button_rounded {
  padding: 3px;
  border-radius: 30px;
  border: 1px solid #004990;
  color: #004990;
  font-size: 16px; /* Ajuste del tamaño de fuente */
  background-color: white;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center; /* Para centrar el texto en mobile */
}

.section-novedades-movil_button_rounded:hover {
  padding: 3px;
  border-radius: 30px;
  border: 1px solid #004990;
  color: white !important;
  background-color: #004990;
  padding-left: 20px;
  padding-right: 20px;
}

.card-no-destacado img.card-img-top.b-radius-10 {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.nuestros-accesos {
  /*font-size: 55px;*/
  font-size: 42pt;
  font-family: "Assistant-VariableFont_wght";
  text-align: left;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: #004990;
}

.nuestros-accesos-movil {
  font-size: 30pt;
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: #004990;
}

.texto-novedad-destacada {
  /* height: 30px; */
  text-align: left;
  color: #05294f;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px !important;
}

.texto-novedad-destacada-sinimagen {
  /* height: 30px; */
  text-align: left;
  color: #05294f;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px !important;
}

.texto-novedad-nodestacada {
  /* height: 30px; */
  text-align: left;
  color: #05294f;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px !important;
  padding-top: 10px;
}

.texto-novedad-movil {
  height: 80px;
  font-size: 18px;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #05294f;
}

.novelty-text-movil {
  /* height: 60px; */
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 20px;
  color: #05294f;
}

.container-buscador-home {
  min-width: "300px";
  width: 60%;
}

.card-body-novedad {
  padding: 0px 1rem !important;
}

.card-no-destacado,
.card-destacado {
  align-self: end;
}

@media (max-width: 768px) {
  .container-novedades-home {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media (min-width: 769px) {
  .container-novedades-home {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.section-image-destacada {
  height: 200px;
  overflow: hidden;
  line-height: 2.4rem;
  padding: 15px 15px 15px 15px;
}

.section-image-destacada .texto-novedad {
  color: red;
}

.section-image-nodestacada {
  overflow: hidden;
  height: 136px;
  color: #05294f;
  padding: 15px 15px 0px 15px;
}

.card-parent-destacada {
  max-height: 350px;
}

.card-parent-nodestacada {
  max-height: 250px;
  height: 250px;
  min-height: 250px;
}

.novelty-title {
  color: #05294f;
  display: block;
  /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;  */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 27px !important;
  font-weight: bold;
  line-height: 2-5rem;
}

.novelty-title-destacado {
  color: #05294f;
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 27px !important;
  font-weight: bold;
  line-height: 2-5rem;
}

.novelty-title-nodestacado {
  color: #05294f;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 19px !important;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .novelty-title-nodestacado {
    -webkit-line-clamp: 3;
  }

  .texto-imagen-movil {
    padding-top: 33px;
  }
}

.novelty-divisor {
  border-bottom: 3px solid #05294f;
  margin: 0rem 1rem;
}

.descripcion-conimagen {
  /* font-weight: bold; */
  -webkit-line-clamp: 2 !important;
}

@media (max-width: 768px) {
  .container-buscador-home {
    min-width: "300px";
    width: 100%;
  }
}

/* .card-destacado  .card{
    height: 466px;
    max-height: 466px;
} */

/* .card-no-destacado  .card{
    height: 350px;
    max-height: 350px;
}

.card-no-destacado .card img{
    width: 100%;
    height: auto;
} */
