@media (max-width: 768px) {
  .texto404 {
    font-size: 16px !important;
  }
  .button-continuar {
    margin-top: 30px !important;
  }
  .container-principal {
    padding: 18px !important;
    padding-top: 0 !important;
    /* margin-top: 8px !important; */
  }
}

.button-continuar {
  width: 180px;
  margin-top: 60px;
}

.texto404 {
  font-family: "Assistant-VariableFont_wght";
  color: #05294f;
  font-weight: bold;
  font-size: 20px;
}

.container-principal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  margin-top: 20px;
}
