.bg-novedades{
    background-image: url('../../assets/img/bg-novedades.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.card-prensas{
    min-height: 215px;
    height: 100% !important;
}

.card-prensas.bg-azul{
    background: #05294F;
    color: white
}


.card-prensas .card-title {
    margin-top: 10px;
    font-size: 28px;
    font-family: Assistant-VariableFont_wght;
    line-height: 34px;
    font-weight: 700;
}

.card-prensas .card-title.opc2 {
    font-size: 23px;
    line-height: 30px;
    font-family: Assistant-VariableFont_wght;
    color: #05294f;
    font-weight: 600;
}

.card-prensas .card-title.opc3 {
    color: #05294f
}

.card-prensas .card-foto img {
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    height: 170px;
}


.card-descripcion-corta.opc1{
    font-size: 22px;
}

.card-descripcion-corta.opc3 {
    font-size: 22px;
    color: #05294f;
}

.separador-prensa-opc1
{        
    border-top: white 3px solid;    
}

.separador-prensa-opc2
{        
    border-top: #05294f 3px solid;    
}


.card-prensas .card-footer{
    border:0px !important;
    background-color: transparent !important;
}

.card-prensas hr.separador.opc1 {
    opacity: 0.6 !important;
    height: 3px !important;
    color: white
}
.card-prensas hr.separador.opc3 {
    opacity: 0.6 !important;
    height: 3px !important;
    color: #05294f
}

.card-prensas{
    -webkit-box-shadow: 6px 11px 24px 3px rgba(0,73,144,0.18);
box-shadow: 6px 11px 24px 3px rgba(0,73,144,0.18);
}

.title_novedades_h2{
    font-size: 65px;
    color: #05294f;
    font-family: Assistant-VariableFont_wght;
    font-weight: 700;
}


.separador-textos{
    width: 2px;
    height: 20px;
    margin-right: 5px;
    margin-left: 5px;  
}
.separador-textos-azul{
  border-right: 2px solid white; 
}

.separador-textos-blanco{
    border-right: 2px solid #05294f;    
  }

  #contenedor-filtrar
  {
    display: flex;    
    cursor: pointer;
  }

  #contenedor-filtrar span{
    color: #05294f;
    font-family: Assistant-VariableFont_wght;
    font-weight: 700;
    margin-right: 5px;
  }


  /* CLASES PARA EL MODAL CUSTOM */

  /* .modal-filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;    
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-filter-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    width: 100vw;
    max-width: 400px;
  }
  
  
  .modal-filter-content::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 20px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
  } */

  .button-filter-prensa {
    /* width: 130px; */
    padding: 5px 20px 5px 20px !important;
    color: #05294f !important;
    border: 1px solid #05294f !important;
    font-weight: 600 !important;
    font-family: 'Assistant-VariableFont_wght';
    border-radius: 30px !important;
    -webkit-box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.13);
    box-shadow: -1px 6px 12px -2px rgba(0, 0, 0, 0.13);
}

.button-filter-prensa:hover {
    /* color: white; */
    font-weight: 800;    
    /* border: 1px solid #05294f !important; */
    /* font-family: 'Assistant-VariableFont_wght'; */
    /* border-radius: 30px */
}

.btn-hb-primary-without-border {
    background-color: #05294f !important;
    /* border: 1px solid #0367c3 !important; */
    border-radius: 20px !important;
    color: #fff !important;
    font-family: Assistant-SemiBold;
    font-size: 28px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0;
    padding: 1px 20px !important;
    font-family: 'Assistant-VariableFont_wght' !important;
  }

  .number-hb-paginate {
    align-items: center;
    display: flex;
    font-family:'Assistant-VariableFont_wght';
    justify-content: center;    
  }

  #paginate, .col-years {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .number-hb-paginate a {
    color: #05294f;
    font-weight: 800;
  }

  .popover-positioned-bottom{
    z-index: 0;
  }

  #aplicar-filtro-fecha{
    cursor: pointer;
  }

  .con-error
  {
    border: 1px solid red;
  }

  .sin-margen
  {
    margin:0px !important;
  }

  .boton-aplicar:active
  {
    opacity: 0.7;
  }