/* PC*/
@media (min-width: 770px) {
  .button_turnos {
    padding-bottom: 11px;
  }
}

.bg-novedades {
  background-image: url("../../assets/img/bg-novedades.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.card-novedades {
  min-height: 375px;
}

.card-novedades.bg-azul {
  background: #05294f;
  color: white;
}

.card-novedades .card-title {
  margin-top: 10px;
  font-size: 28px;
  font-family: Assistant-VariableFont_wght;
  line-height: 34px;
  font-weight: 700;
}

.card-novedades .card-title.opc2 {
  font-size: 23px;
  line-height: 30px;
  font-family: Assistant-VariableFont_wght;
  color: #05294f;
  font-weight: 600;
}

.card-novedades .card-title.opc3 {
  color: #05294f;
}

.card-novedades .card-foto img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 170px;
}

.card-descripcion-corta.opc1 {
  font-size: 22px;
}

.card-descripcion-corta.opc3 {
  font-size: 22px;
  color: #05294f;
}

.card-novedades hr.separador.opc1 {
  opacity: 0.6 !important;
  height: 3px !important;
  color: white;
}
.card-novedades hr.separador.opc3 {
  opacity: 0.6 !important;
  height: 3px !important;
  color: #05294f;
}

.card-novedades {
  -webkit-box-shadow: 6px 11px 24px 3px rgba(0, 73, 144, 0.18);
  box-shadow: 6px 11px 24px 3px rgba(0, 73, 144, 0.18);
}

.title_novedades_h2 {
  font-size: 65px;
  color: #05294f;
  font-family: Assistant-VariableFont_wght;
  font-weight: 700;
}

.container-buscador-esp {
  min-width: "300px";
  width: 60%;
}

@media (max-width: 768px) {
  .container-buscador-esp {
    min-width: "300px";
    width: 100%;
  }

  .card-doctor-large {
    display: none !important;
  }

  .card-doctor-small {
    margin-bottom: -30px;
  }

  .scrollableNavDiv {
    width: calc(100% - 20px);
  }
}

/*************************************/
.baseTab {
  border-left: 1px solid #e9ecef !important;
  cursor: pointer;
}

.activeTab {
  background-color: #e4e4e4 !important;
  /* color: white !important; */
}

.card-doctor {
  display: flex;
  flex-direction: column; /* Coloca los elementos uno debajo del otro */
  align-items: center; /* Centra verticalmente los elementos hijos */
  margin-top: 20px !important;
}

.picture {
  color: red;
  /* background-color: #05294f; */
  border-radius: 50%;
  box-shadow: 0px 0px 15px 2px rgb(217, 208, 208);
  position: relative;
  z-index: 2;
  /* height: 100%; */
  width: 100%;
  object-fit: cover;
  max-width: 150px;
  max-height: 150px;
  height: 150px;
  /* overflow: hidden; */
}

.data {
  /* color: green; */
  border-radius: 16px;
  box-shadow: 0px 0px 15px 2px rgb(217, 208, 208);
  padding-top: 90px;
  position: relative;
  top: -70px;
  z-index: 1;
  display: flex;
  flex-direction: column; /* Coloca los elementos uno debajo del otro */
  align-items: center; /* Centra verticalmente los elementos hijos */
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  font-family: "Assistant-VariableFont_wght";
}

.nombre-doctor {
  color: #05294f;
  font-size: 20px;
  font-weight: bold;
}

.rol-doctor {
  /* color: #05294f; */
  font-weight: bold;
}

.card-especialidad {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 20px !important; */
}

.sub-especialidad {
  /* color: green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Assistant-VariableFont_wght";
  /* margin: auto; */
}

.blueLineStyle {
  background-color: #05294f;
  border-color: #05294f;
  height: 2px;
  width: 95px !important;
  /* margin: 10px 0; */
}

.titulo-subEspecialidad {
  margin-bottom: 1rem;
  color: #05294f;
  text-align: center;
  width: 80%;
}

.descripcion-equipo {
  margin-bottom: 1rem;
  color: #05294f;
  text-align: center;
  width: 75%;
}

.cards-equipo {
  width: 100%;
  /* margin: 0 !important; */
  margin-bottom: -45px !important;
}

/* //************************************************* */
.accordion {
  max-width: 600px;
  margin: 0rem auto;
  color: #05294f;
  font-family: "Assistant-VariableFont_wght";
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 600;
  font-size: large;
  border-color: black;
  /* background-color: #21aeca; */
  box-shadow: 0px 3px 15px 2px rgb(217, 208, 208) !important;
  border-radius: 10px;
}

.accordion-item {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 20px 2px rgb(217, 208, 208) !important;
  padding-top: 0;
  padding-bottom: 0;
}

.accordion-title-empty {
  cursor: auto;
}

.accordion-title,
.accordion-content {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.accordion-open {
  /* padding: 15px; */
  padding-top: 0;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
}

.accordion-item-open {
  padding-bottom: 8px;
  /* padding-top: 8px; */
}

.scrollableNav {
  /* overflow-x: auto !important; */
  flex-wrap: nowrap !important;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px !important;
}
.scrollableNavDiv {
  overflow-x: auto !important;
  position: absolute;
}

.btn-hb-secondary-without-border {
  color: #033261 !important;
  background-color: #fff !important;
  border: 1px solid #033261 !important;
  border-radius: 20px !important;
  padding: 1px 20px 1px 20px !important;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  /* font-family: "Assistant-SemiBold"; */
  font-family: "Assistant-VariableFont_wght";
}

.titulo-eliminar-familiar {
  color: #05294f;
  padding-left: 20px;
  font-family: "Assistant-VariableFont_wght";
  font-size: 30px;
  align-self: center;
  margin-bottom: 15px;
  line-height: normal;
  font-weight: 500;
}

.modal-content {
  border-radius: 20px !important;
}

.boton-info-jefe
{
  width: fit-content !important;
  height: fit-content !important;
  font-size: 14px !important;
  padding: 5px 25px 5px 25px !important;
  margin-bottom: 30px !important;
}