#titulo-filtro
{
    color: #05294f;
    font-family: Assistant-VariableFont_wght;
    font-weight: bold;    
    font-size: 18px;
}


.cabecera-filtro
{
    /* margin-bottom: 20px; */
}

.cabecera-filtro-mobile
{
  padding: 0px 10px;    
}

#borrar-filtros
{
    color: #05294f;
    font-family: Assistant-VariableFont_wght;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
    display: block;
}

#borrar-filtros-mobile
{
    color: #05294f;
    font-family: Assistant-VariableFont_wght;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
    display: block;
    padding: 0px 10px;
}

.elementos-filtrados
{
    color: #05294f;
    font-family: Assistant-VariableFont_wght;    
    /* display: grid;
    grid-template-columns: auto 10px;
    gap: 0.5em; */
    background-color: #d9d9d9;
    border-radius: 10px;    
    padding: 5px 10px;
    font-weight: bold;
    width: fit-content;
}

#contenido-elementos-filtrados
{
    display: grid;
    grid-template-columns:auto;
    gap: 0.5em;
}

#contenido-elementos-filtrados-mobile
{
    display: grid;
    grid-template-columns:auto;
    gap: 0.5em;    
    padding: 0px 10px;
}


.eliminar-seleccionado
{
    cursor: pointer;    
    margin-left: 10px;
}

.elementos-filtrados > *{
    /* display: block;     */
}

.categories {
    list-style: none;
    padding: 0;
    color: #05294f;
    font-family: Assistant-VariableFont_wght;
    font-weight: 600;
}


.categories li {
    margin-bottom: 20px;
}

.categories h3 {
    margin: 0;
    font-size: 18px;
}

.subcategories {
    list-style: none;
    margin-top: 5px;
    padding-left: 0px;
}

.subcategories li {
    margin-bottom: 5px;
}

  .checkbox {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;    
    width: 14px;
    height: 14px;
    border: 1px solid #05294f;     
    transform: translateY(-0.075em);  
    display: grid;
    place-content: center;    
  }
  
  .checkbox::before {
    content: "";
    width:10px;
    height: 10px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;    
    /* Windows High Contrast Mode */    
    background-color: CanvasText;    
  }
  
  .checkbox:checked::before {
    transform: scale(1);
  }
  
  
  .checkbox:disabled {
    --form-control-color: var(--form-control-disabled);
  
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }
  
  
.label-subcategoria
{      
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}


.contenedor-principal-filtros
{
  /* z-index: 1; */
  position: relative;  
}

.contenedor-principal-filtros-mobile
{ 
/* padding: 10px; */
}

.grupo-subcategorias-mobile
{  
  background-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* .overlay-filtros
{
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10;
  position: absolute;  
  margin-left: -20px;
  border-radius: 10px;   
  display: none;    
}

@keyframes fadeIn-custom {
  from {
    opacity: 0;
    
  }
  to {
    opacity: 0.25; 
    
  }
}

@keyframes fadeOut-custom {
  from {
    opacity: 0.25;
    
  }
  to {
    opacity: 0;
    
  }
}

.fadeInOut-custom {
  
}

.fade-custom {
  animation: fadeIn-custom 2s ease-in-out forwards, fadeOut-custom 2s ease-in-out forwards;  
  display: block;
} */


@keyframes fadeInCustom {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutCustom {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}

.fadeInOut-custom {
  animation: fadeInCustom 1s ease-in-out forwards;
}

.fade-custom {
  animation: fadeOutCustom 2s ease-in-out forwards;
}

.filtro-principal-mobile
{
  cursor: pointer;
}

.popover-filtro-categorias
{
  min-width: 300px;
}

.componente-categoria-mobile
{  
  height: 35px;
  display: flex;    
  margin: 0px .5rem !important;
  justify-content: space-between;
}

.borde-categoria
{
  border-bottom: 1px solid #ABC3DA;  
}

.categories .grupo-subcategorias-mobile:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.nombre-categoria
{
  line-height: 35px;
  font-size: 18px;
}

.icono-arrow
{
  line-height:35px !important;
  color:#d9d9d9;
  font-size:18px;
  cursor:pointer
}

.todos-subcategorias
{
  cursor: pointer;
  font-size: 16px;
}

.boton-filtros
{
  max-width: 110px;
}

.boton-filtros:active
{
  opacity: 0.7 !important;
}